/* ===========================================================
 * zh_tw.js
 * Traditional Chinese translation for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Peter Dave Hello (PeterDaveHello)
 *          Twitter : @PeterDaveHello
 *          Github : https://github.com/PeterDaveHello
 */
// jshint camelcase:false
jQuery.trumbowyg.langs.zh_tw={viewHTML:"原始碼",formatting:"格式",p:"段落",blockquote:"引用",code:"代碼",header:"標題",bold:"加粗",italic:"斜體",strikethrough:"刪除線",underline:"底線",strong:"加粗",em:"斜體",del:"刪除線",unorderedList:"無序列表",orderedList:"有序列表",insertImage:"插入圖片",insertVideo:"插入影片",link:"超連結",createLink:"插入連結",unlink:"取消連結",justifyLeft:"靠左對齊",justifyCenter:"置中對齊",justifyRight:"靠右對齊",justifyFull:"左右對齊",horizontalRule:"插入分隔線",fullscreen:"全螢幕",close:"關閉",submit:"確定",reset:"取消",required:"必需的",description:"描述",title:"標題",text:"文字"};